import { graphql } from "gatsby"
import React from "react"

import Contact from "../components/contact"
import GA from "../components/ga"
import Hero from "../components/hero"
import Layout from "../components/layout"
import styles from "../components/scss/team.module.scss"
import SEO from "../SEO"


class ContactPage extends React.Component {
  render() {

    const { data } = this.props
    return (
      <>
        <GA />
        <Layout>
          <SEO
            title="About"
            pathname="/contact"
            description={data.contentfulSeo.contactPageSeoDescription}
          />
          <Hero
            slogan={data.contentfulContactPageHeroSlogan.slogan}
            cta={false}
          />
          <section className={styles.about} id="about">
            <Contact />
          </section>
        </Layout>

      </>
    )
  }
}

export const query = graphql`
  {
    contentfulSeo {
      contactPageSeoDescription
    }
    contentfulContactPageHeroSlogan {
      slogan
    }
  }
`

export default ContactPage
